<script lang="ts" setup>
import { ref, computed, defineAsyncComponent } from 'vue'
import SubMenus from './SubMenus.vue'
import { getServerState, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { useContentStore } from '@/store/content'

const ZgIcon = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then((c) => c.ZgIcon))

const ctx = import.meta.env.SSR
  ? useSSRContext()
  : {
      market: getServerState('market'),
      route: getServerState('route'),
    }

const content = useContentStore().getContent
const items = computed(() => content?.menu ?? [])
const hoverItem = ref<number | null>(null)
const setHoverItem = (index: number | null) => { hoverItem.value = index }

defineEmits(['track-navigation-click'])
</script>

<template>
  <div :class="['shortcuts', { [`shortcuts--${ctx?.market}`]: ctx?.market }]">
    <ul>
      <li
        v-for="(item, index) in items"
        :key="index"
        :aria-label="item.title"
        aria-haspopup="true"
        @mouseover="() => setHoverItem(index)"
        @mouseleave="() => setHoverItem(null)"
      >
        <a
          v-if="item.url"
          :href="item.url"
          :class="{ current: ctx?.route?.pathname === item.url }"
          tabindex="0"
          @click="$emit('track-navigation-click', item.url)"
        >
          {{ item.title }}
          <zg-icon v-if="item?.subMenu?.length" icon="chevron" size="small" />
        </a>
        <sub-menus
          v-if="item?.subMenu?.length"
          :sub-menu="item.subMenu"
          :articles="item.articles"
          :visible="hoverItem === index"
          @navigation-click="$emit('track-navigation-click', $event)"
        />
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  @use '/vue/scss/breakpoints' as breakpoints;

  .shortcuts {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    gap: rem(16px);
    height: 100%;
  }

  ul {
    display: none;
    flex-direction: row;
    flex-grow: 1;
    height: 100%;
    list-style: none;
    padding: rem(8px) 0 0;
    margin: 0;

    @include mq(large) {
      display: flex;
    }

    li {
      position: relative;
      height: 100%;
      padding: 0;
      margin: 0;
    }

    a {
      @include type-title-xs;

      color: var(--black);
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: rem(8px);
      height: 100%;
      font-weight: font-weight(bold);
      padding: rem(8px) rem(12px) rem(16px);
      margin: 0;
      white-space: nowrap;
      border: none;
      cursor: pointer;
      transition: 0.15s;
      border-radius: rem(20px) rem(20px) 0 0;

      :deep(svg) {
        transition: all 0.3s ease;
      }

      &.current {
        background-color: var(--brand-100);
      }
    }

    li:hover {
      a {
        color: var(--brand-500);
        background-color: var(--beige);

        :deep(svg) {
          color: var(--brand-500);
          transform: rotate(180deg);
        }
      }
    }
  }

  // ? ----------------------------------------------------------------------
  // modifiers
  // ? ----------------------------------------------------------------------

  .shortcuts--se {
    @include mq($until: breakpoints.$se-breakpoint-desktop) {
      display: none;
    }
  }

  .shortcuts--no {
    @include mq($until: breakpoints.$no-breakpoint-desktop) {
      display: none;
    }
  }

  .shortcuts--fi {
    @include mq($until: breakpoints.$fi-breakpoint-desktop) {
      display: none;
    }
  }
</style>
