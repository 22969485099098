<script lang="ts" setup>
import { getServerState, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { useMiscStore } from '@/store/misc'
import { computed } from 'vue'

const ctx = import.meta.env.SSR ? useSSRContext() : { market: getServerState('market') }

const miscStore = useMiscStore()
const close = computed(() => miscStore.showMenu)

defineEmits(['toggle-menu'])
</script>

<template>
  <div
    tabindex="1"
    :class="['hamburger', {
      'hamburger--close': close,
      [`hamburger--${ctx?.market}`]: ctx?.market
    }]"
    @click.prevent="$emit('toggle-menu')"
    @keyup.space="$emit('toggle-menu')"
  >
    <div>
      <span />
      <span />
      <span />
      <span />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use '/vue/scss/breakpoints' as breakpoints;

  .hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(24px);
    height: rem(24px);

    div {
      position: relative;
      width: rem(18px);
      height: rem(12px);
      transform: rotate(0deg);
      transition: 0.5s ease-in-out;
      cursor: pointer;
    }

    span {
      position: absolute;
      left: 0;
      width: 100%;
      height: rem(2px);
      background: var(--black);
      border-radius: rem(2px);
      opacity: 1;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: rem(5px);
      }

      &:nth-child(4) {
        top: rem(10px);
      }
    }
  }

  // ? ----------------------------------------------------------------------
  // modifiers
  // ? ----------------------------------------------------------------------

  .hamburger--close {
    span:nth-child(1) {
      top: rem(5px);
      width: 0;
      left: 50%;
    }

    span:nth-child(2) {
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      top: rem(5px);
      width: 0;
      left: 50%;
    }
  }

  .hamburger--se {
    @include mq(breakpoints.$se-breakpoint-desktop + 1) {
      display: none;
    }
  }

  .hamburger--no {
    @include mq(breakpoints.$no-breakpoint-desktop + 1) {
      display: none;
    }
  }

  .hamburger--fi {
    @include mq(breakpoints.$fi-breakpoint-desktop + 1) {
      display: none;
    }
  }
</style>
