import { useServerHead } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'

export default function useScripts ({
  isSweden = false,
  isFinland = false,
  isNorway = false,
  isZmarta = false,
  isBroker = false,
  isProduction = false,
}) {
  useServerHead(() => {
    const scripts: Array<Record<string, any>> = []
    const cookiebotConfigs = {
      swedenZmarta: '331c262e-2f29-4826-a52d-058fec8d2690',
      finlandZmarta: '91490bf6-aa44-4cf8-82b2-86b161236965',
      norwayZmarta: '7546b09d-7e36-4fa2-8f2c-c64f7cb22190',
      swedenBroker: '628e1df0-6019-4553-9868-d98f0b27e9d7',
    }

    // Cookiebot script
    const addCookiebotScripts = (cbid: string) => {
      scripts.push({
        id: 'Cookiebot',
        src: 'https://consent.cookiebot.com/uc.js',
        'data-cbid': cbid,
        type: 'text/javascript',
        async: true,
      })
    }

    if (isProduction) {
      if (isSweden && isZmarta) addCookiebotScripts(cookiebotConfigs.swedenZmarta)
      if (isFinland && isZmarta) addCookiebotScripts(cookiebotConfigs.finlandZmarta)
      if (isNorway && isZmarta) addCookiebotScripts(cookiebotConfigs.norwayZmarta)
      if (isSweden && isBroker) addCookiebotScripts(cookiebotConfigs.swedenBroker)
    }

    return {
      script: scripts,
    }
  })
}
